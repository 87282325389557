<div style="float:left; width:100%;">
  <form name="detailFrm" id="detailFrm" novalidate="novalidate">
      <input type="hidden" name="brdTyCd" id="brdTyCd" value="20170206112947733729">
      <input type="hidden" name="pstSeq" id="pstSeq" value="20230217155547508080">
  </form>
  <div style="display:none;">
      <div id="attachFileListUl"></div>
  </div>

  <div style="padding:10px 10px 10px 10px;">
      <div>
          <span id="title" style="font-size:20px;">{{contents.title}}</span>
      </div>
      <div style="height:15px; font-size:12px;">
          <div style="float:left; width:120px;">
              date : <span id="workDate">{{contents.insDate}}</span>
          </div>
          <div style="float:left;">
              view : <span id="readCnt">{{contents.readCnt}}</span>
          </div>
      </div>
      <div id="titlLine" style="height:5px; border-bottom:1px solid #888888;">
      </div>
      <div style="height:10px;"></div>
      <div class="post-body" id="postBody">
        <div [innerHTML]="contents.contents ? (contents.contents | renderHtml) : ''"></div>
      </div>
  </div>

  <!-- button start -->
  <!-- button end -->

  <!-- pre,next start -->
  <!-- pre,next end -->

  </div>