import { Pipe, PipeTransform } from "@angular/core";
import { AuthUtil } from "@app/common/util/auth.util";
import { ConvertUtil } from "@app/common/util/convert.util";

@Pipe({ name: "cusprice" })

export class CustomerPrice implements PipeTransform {

    transform(customerPrice: String | Number | string | number | undefined, memberPrice: String | Number | string | number | undefined): String {
        
        let member = AuthUtil.getLoginedInfo();
        if(member){
            let userKind = ConvertUtil.convertToSring(member?.userKind).substr(3, 3);
            if(userKind == '00'){
                return ConvertUtil.convertToSring(customerPrice);
            }else{
                return ConvertUtil.convertToSring(memberPrice); 
            }
        }else{
            return ConvertUtil.convertToSring(customerPrice);
        }
        
    }
}