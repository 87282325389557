
import { Pipe, PipeTransform } from '@angular/core';
import { ConvertUtil } from '@app/common/util/convert.util';
declare const addZeroInPrice: any;
@Pipe({ name: "setComma" })
export class SetCommaPipe implements PipeTransform {

  constructor() {}

  transform(value: String | Number | string | number | undefined, type : String | string | undefined): String {
    
    if (!value) {
      return "0";
    }else{
      //addZeroInPrice(value);
      // if(type == 'price'){
      //   return addZeroInPrice(value);
      // } else{
      //   return ConvertUtil.setComma(value);
      // }
      return ConvertUtil.setComma(value);
    }
  }
}