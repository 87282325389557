import { ValidationUtil } from '@app/common/util/validation.util';
import { environment } from '@enviroments/environment';
import { CompanyConstant } from '../constant/company.constant';
import { LangConstant } from '../constant/lang.constant';
import { AuthUtil } from './auth.util';
import { ConvertUtil } from './convert.util';

export class CommonUtils {

  static getMessages(): string {
    return 'messages.' + CommonUtils.getLangMessage();
  }

  static getLangMessage(): string {
    let res = environment.default_lang.toLowerCase();
    let lang = CommonUtils.getLang();

    switch (lang) {
      case 'kr':
        res = 'kr';
        break;
      case 'en':
        res = 'en';
        break;
      case 'us':
        res = 'en';
        break;
      default:
        res = 'en';
        break;
    }

    return res;
  }

  static getLang(): string {
    let loginedInfo = AuthUtil.getLoginedInfo();
    let lang = sessionStorage.getItem(LangConstant.STR_LANG);

    if (ValidationUtil.isNullOrEmpty(lang)) {
      if (loginedInfo) {
        lang = ConvertUtil.convertToSring(loginedInfo.ctrCd);
      } else {
        lang = environment.default_lang;
      }
      sessionStorage.setItem(LangConstant.STR_LANG, lang);
    }

    return ConvertUtil.convertToSring(lang).toLowerCase();
  }

  // static isCompanyUS(){
  //   return CompanyConstant.COM_ID_MEDIUS == environment.comId //TODO
  // }
  // static isLangUS(){
  //   return (CommonUtils.getLang() == 'en' || CommonUtils.getLang() == 'EN');
  // }

  static plus(number1 : any, number2 : any) : number {
    return Number(number1) + Number(number2);
  }

  static minus(number1 : any, number2 : any) : number {
    return Number(number1) - Number(number2);
  }

  static getListFromCurrentMonth(currentMonth : number) {
    let totalMonth = 12 - currentMonth;
    if(totalMonth === 0 || currentMonth > 12 || currentMonth <= 0) {
      return Array.from({length : 12}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(i, 1)));
    }
    return Array.from({length: totalMonth}, (e, i) => ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentMonth, i + 1)));
  }

  static getListFromCurrentYear(currentYear : number) {
    return Array.from({length: 10}, (e, i) => {
      return ConvertUtil.convertToZeroDecimal(CommonUtils.plus(currentYear, i));
    });
  }
}

