import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { detailNotice, listFile } from '@app/actions/myoffice/notice/notice.action';
import { setShowOverlayLoading } from '@app/actions/overlay-loading.action';
import { ValidationUtil } from '@app/common/util/validation.util';
import { BoardModel } from '@app/models/myoffice/notice/board.model';
import { SmWownetModel } from '@app/models/system/sm-wownet.model';
import { NoticeDetailState, getDetailNoticeItems, getListFileItems } from '@app/selectors/myoffice/notice/notice-detail.selector';
import { OverlayLoadingState } from '@app/selectors/overlay-loading.selector';
import { SmWownetState, getSmWownet } from '@app/selectors/system/sm-wownet.selector';
import { environment } from '@enviroments/environment';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

declare const adjustPostContent: any;

@Component({
  selector: 'app-notice-layout',
  templateUrl: './notice-layout.component.html',
  styleUrls: ['./notice-layout.component.css']
})
export class NoticeLayoutComponent implements OnInit {

  boardNo: String = "";
  contents : any;

  noticeDetail$ = new Observable<BoardModel>;
  listFile$ = new Observable<BoardModel[]>;

  noticeDetail: BoardModel = {} as BoardModel;

  params : any = "";

  smWownet$ = new Observable<SmWownetModel>;
  urlWownet : String = "";

  fileDownload : any = {};

  constructor(
    private _noticeDetailStore: Store<NoticeDetailState>,
    private _overlayLoadingStore: Store<OverlayLoadingState>,
    private _smWownetStateStore: Store<SmWownetState>,
    private http : HttpClient,
    private route: ActivatedRoute
  ) { 
    this.smWownet$ = this._smWownetStateStore.select(getSmWownet);
  }

  ngOnInit(): void {
    this.urlWownet = environment.apiUrl.slice(0, -4);
    
    this.route.queryParams.subscribe(params => {
       this.boardNo = params['boardNo'];
       this.onSearch();
    });
  }

  onSearch() :void{
    setTimeout(() => {
      this._overlayLoadingStore.dispatch(setShowOverlayLoading({ loading: true }));
    }, 1);

    const url = `${environment.apiUrl}/board/findBoardById/search?comid=${environment.comId}&boardid=${this.boardNo}`;
    this.http.get(url).subscribe(
      response => {
        this.contents = response;
      }
    );

    setTimeout(() => {
      this.updateSrcForElement();
      this._overlayLoadingStore.dispatch(setShowOverlayLoading({ loading: false }));
    }, 1000);
  }

  updateSrcForElement() {
    let e = document.getElementById('postBody') as HTMLElement; 
    adjustPostContent(e, this.urlWownet);
  }

}
